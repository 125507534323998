<template>
	<div><h1>Welcome To Fine Grounds!</h1></div>
</template>

<script>

export default {

	name: 'BusinessWelcomePage',

	async asyncData({ app }) {
		try {
			await app.$api.post('/business/locations/reload');
		} catch (e) {
			app.$store.dispatch('errorToast', 'Unable to load business info');
		}
	},

};

</script>
